import React from 'react'
import './inEngineAnimation.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'

const Fade = loadable(() => import('../transition/Fade'))

const InEngineAnimation = ({ data }) => {
  return (
    <div className="engine-container d-lg-flex align-items-lg-center justify-content-between" id="common-pl">
      <div className="title-container first-flex-column">
        <p className="sub-title">MORE ABOUT</p>
        <Fade timeout={500}>
          <h2>{data.title}</h2>
        </Fade>
        <Fade timeout={1000}>
          <p className="title-dec">
            <Marked parserOptions={{ commonmark: true}} source={data.description} />
          </p>
        </Fade>
      </div>
      <div className="img-container second-flex-column">
        <Fade timeout={1000}>
          <img src={data.image} alt={data.alt} className="engine-img" />
        </Fade>
      </div>
      <div className="bg-text-container d-none d-lg-block">
        <img src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325527/ANIMA_twcwuc.png" alt="animation" className="anima-img" />
      </div>
    </div>
  )
}

export default InEngineAnimation
